import React from "react";
import "./App.css";
import "./App.scss";
import { BreakpointProvider, setDefaultBreakpoints } from "react-socks";
import { Provider } from "react-redux";
import store, { persistor } from "./store";
import Routers from "./Routers";
// import "react-toastify/dist/ReactToastify.css";
// import { GoogleOAuthProvider } from "@react-oauth/google";

// persistor.purge();

const App = () => {
  // setDefaultBreakpoints([
  //   { xsmall: 0 }, // all mobile devices
  //   { small: 576 }, // mobile devices (not sure which one's this big)
  //   { medium: 768 }, // ipad, ipad pro, ipad mini, etc
  //   { large: 992 }, // smaller laptops
  //   { xlarge: 1200 }, // laptops and desktops
  //   { xxlarge: 1400 }, // laptops and desktops
  // ]);

  return (
    // <GoogleOAuthProvider clientId="539203210787-o7qctg1psslb9h9b9qj97d7kf3hbm66q.apps.googleusercontent.com">
    <BreakpointProvider>
      <Provider store={store}>
        <Routers />
      </Provider>
    </BreakpointProvider>
    // </GoogleOAuthProvider>
  );
};

export default App;
