import React, { useEffect, useState } from 'react';
// import './App.css'; // Make sure to import Tailwind CSS here if not globally imported

const Countdown = () => {
  const calculateTimeLeft = () => {
    const endDate = new Date("June 10, 2024 10:00:00").getTime();
    const now = new Date().getTime();
    const difference = endDate - now;

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
        minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((difference % (1000 * 60)) / 1000),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  return (
    <div className="w-full text-left flex mb-10">
      <ul className="w-full flex place-content-center md:place-content-end gap-4 mx-auto text-gray-50">
        {Object.keys(timeLeft).map((interval) => (
          <li key={interval}>
            <div className="font-bold text-white rounded-full border-dotted border-gray-700 border-4 flex items-center justify-center text-2xl lg:text-4xl h-16 lg:h-24 w-16 lg:w-24">
              {timeLeft[interval] < 10 ? `0${timeLeft[interval]}` : timeLeft[interval]}
            </div>
            <p className="text-center text-xs mt-2">{interval[0].toUpperCase() + interval.slice(1)}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

const App = () => {
  return (
    <div className="font-Noto-Sans">
      <header className="w-full bg-[url('assets/img/leaves.jpg')] bg-cover bg-bottom">
        <div className="w-full min-h-screen md:w-1/2 bg-gradient-to-b from-gray-900/90 to-gray-900/95 p-10 flex">
          <div className="w-full text-center my-auto">
            <h1 className="font-bold text-5xl font-Comforter-Brush text-amber-500 mb-10 md:text-right">Launcher.</h1>
            <h6 className="font-Montserrat font-bold uppercase text-4xl md:text-5xl lg:text-6xl mb-14 md:-mr-20 text-white md:text-right">Coming <span className="text-white/70 md:-mr-20">soon</span></h6>
            <Countdown />
            <p className="text-base mb-10 text-gray-200 md:text-right">Unlocking Opportunities!!</p>
            <div className="w-full text-left flex">
              <ul className="w-full flex place-content-center md:place-content-end gap-10 mx-auto text-gray-500">
                {/* Social icons here */}
              </ul>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
