import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Spinner, Image, Button } from "react-bootstrap";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Home from "pages/Home/Home";

// import { Navbar } from "components";

import axios from "axios";
// import { Breakpoint, useCurrentWidth } from "react-socks";
// import { ToastContainer } from "react-toastify";
// import { persistor } from "store";

const Routers = () => {
  // const token = useSelector((state) => state.authReducer.token);
  // const user = useSelector((state) => state.authReducer.user);
  // const isAuthenticated = useSelector(
  //   (state) => state.authReducer.isAuthenticated
  // );
  // const isAdmin = useSelector((state) => state?.authReducer?.user?.is_admin);
  // const isSuperuser = useSelector((state) => state?.authReducer?.user?.is_superuser);
  // const deviceWidth = useCurrentWidth();

  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  // if (token) {
  //   axios.defaults.headers.common["Authorization"] = "Token " + token;
  // } else {
  //   axios.defaults.headers.common["Authorization"] = null;
  // }

  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (user && token) {
  //     if (!executed) {
  //       dispatch(actions.getMyProfile(cartProducts));
  //       setExecuted(true);
  //     }
  //   }
  // }, [user, token]);

  if (loading) {
    return (
      <Container
        fluid
        className="d-flex justify-content-center align-items-center position-fixed w-100 h-100"
      >
        <Spinner animation="border" style={{ color: "#555" }}>
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  return (
    <Router>
      <Container fluid className="px-0 poppins">
        {/* <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        /> */}

        {/* <Navbar /> */}

        <Routes>
          <Route path="" element={<Home />} />
        </Routes>
        {/* <Footer /> */}
      </Container>
    </Router>
  );
};

export default Routers;
